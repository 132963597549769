import React, { useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import './formulario.css';
import formwords from "./formularioDictionary";
import { useSelector } from "react-redux";
import { languageSwitch } from '../../dictionary/translateFunctions';

const Mensaje = (props) =>{
    useEffect(()=>{
        setTimeout(()=>{
            props.action({type:'none',mensaje:''})
        },6000)
    })
    return (
        <div className={props.data.type === 'error' ? "formularioContacto-mensaje _error":props.data.type === 'ok' ? "formularioContacto-mensaje _ok":null}>{props.data.mensaje}</div>
    )
}

const Formulario = () => {
    const lang = useSelector((state) => state.lang.lang);
    const dictionary = formwords;

    const okM = languageSwitch(dictionary.msgOk,lang)
    const errM = languageSwitch(dictionary.msgErr,lang)

    const formR = useRef();
    const [mensaje,setMensaje] = useState({type:'none',mensaje:''});
    const {register, formState:{errors}, handleSubmit} = useForm();
    const msg = 'Debes llenar este campo.'

    const enviaForm = (data) =>{
        const formulario = document.getElementById('contactForm')
        const form = new FormData(formulario)

        

        /* console.log('Cat: '+form.get('nuevaCatH')+' - '+form.get('categoriaProyecto')) */
        fetch('https://davmund.cl/mailer/mail.php',{
            method:'POST',
            body: form
        })
        .then(res => res.json())
        .then(dataN => {
            //console.log(data);
            return dataN === 'ok' ? (setMensaje({type:'ok',mensaje:okM}),formR.current.reset()):
                                    setMensaje({type:'error',mensaje:errM})
        })
    }
    return (
        <form ref={formR} id='contactForm' className="formularioContacto" style={{gridColumn: `1 / 7`}} onSubmit={handleSubmit(enviaForm)}>
            {mensaje.type !== 'none' ? <Mensaje action={setMensaje} data={mensaje} />:''}
            <label htmlFor="nombre">
                {languageSwitch(dictionary.lb1,lang)}
                <input id="nombre" type="text" placeholder={languageSwitch(dictionary.plh1, lang)} className="formShadow"
                {...register('nombre',{required:true})} />
                {errors.nombre?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <label htmlFor="mail">
                {languageSwitch(dictionary.lb2,lang)}
                <input id="mail"  type="email" placeholder={languageSwitch(dictionary.plh2, lang)} autoComplete='email' className="formShadow"
                {...register('mail',{required:true})} />
                {errors.mail?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <label htmlFor="asunto">
                {languageSwitch(dictionary.lb3,lang)}
                <input id="asunto" name="asunto" type="text" placeholder={languageSwitch(dictionary.plh3, lang)} className="formShadow"
                {...register('asunto',{required:true})} />
                 {errors.asunto?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <label htmlFor="mensaje">
                {languageSwitch(dictionary.lb4,lang)}
                <textarea id="mensaje" name="mensaje" className="formShadow"
                {...register('mensaje',{required:true})} />
                {errors.mensaje?.type==='required' && (<span className='input_error_msg'>{msg}</span>)}
            </label>
            <button type="submit">{languageSwitch(dictionary.lb5,lang)}</button>
        </form>
    )
}

export default Formulario;