import React from 'react';
import { languageSwitch } from '../../dictionary/translateFunctions';
import { useSelector } from 'react-redux';
import Fecha from '../fecha/Fecha'
import './pod.css';

const Pod = (props) => {
    const lang = useSelector((state) => state.lang.lang);
    return (
        <div className="aboutme_card">
            <div className="aboutme_card_arrow firstline">
            <div className="aboutme_card_left"></div>
            </div>

            <div className="aboutme_card_block">
                <div className={props.type === 'exp' ? 'aboutme_card_head' : 'aboutme_card_head_2'}>
                    <h5 className="aboutme_card_title">
                        {props.type === 'exp' ? languageSwitch(props.objeto.nombre_exp,lang) : languageSwitch(props.objeto.nombre_ed,lang)}
                    </h5>
                    {props.type === 'exp' ? 
                        (<h6 className="aboutme_card_subtitle">{props.objeto.institucion_exp} 
                            <span className="aboutme_card_fecha"> 
                                <Fecha date={props.objeto.fecha_inicio} /> - <Fecha date={props.objeto.fecha_hasta} />
                            </span>
                        </h6>): 
                        (<h6 className="aboutme_card_subtitle">{props.objeto.institucion_ed} 
                            <span className="aboutme_card_fecha">
                                <Fecha date={props.objeto.fecha_desde} /> - <Fecha date={props.objeto.fecha_hasta} />
                            </span>
                        </h6>)
                    }
                </div>
                <div className={props.type === 'exp' ? 'aboutme_card_body':'aboutme_card_body_2'}>
                    <span className="aboutme_card_p">
                        {props.type === 'exp' ? 
                        /* (props.objeto.descripcion_exp.split('<br />').map((parrafo)=>{return <p>{parrafo}</p>})) : 
                        (props.objeto.descripcion_ed.split('<br />').map((parrafo)=>{return <p>{parrafo}</p>}))} */
                        languageSwitch(props.objeto.descripcion_exp, lang):languageSwitch(props.objeto.descripcion_ed, lang)}
                    </span>
                </div>
            </div>
            
        </div>
    )
}

export default Pod;