const languageSwitch = (data,val) => {
    let text = '';

    switch (val) {
        case 'es': data.es? text = data.es : text = data;console.log('es: '+data.es)
        break;
        case 'en': data.en? text = data.en: text = data.es;console.log('en: '+data.en)
        break;
        default:
            text = data;
            break;
    }
    /* chekParrafos(text) */
    return chekParrafos(text);
}

const chekParrafos = (t) => {
    const m = new RegExp(/\. /g);
    const test = m.test(t);

    switch(test){
        case true:
            const elem = t.replace(m, ".<br /> ")
            const arr = elem.split('<br />');
            return arr.map((pr, index) => pr!==''?<p key={index}>{pr}</p>:'');
        case false:
            return t;
        default:
            return null;
    }

}

export {languageSwitch};