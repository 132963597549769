const aboutwords ={
    'abtt1':{
        'es':'¿Quién soy?',
        'en':'who I am?'
    },
    'abtst1':{
        'es':'Como profesional',
        'en':'As a professional'
    },
    'abtdes1':{
        'es':'Diseñador Gráfico Publicitario de la Universidad Santo Tomás, con más de 14 años de experiencia en diseño digital, integración y diseño interfaz de usuario, principalmente en rubros como comercio electrónico y Ti. Como profesional, me destaco por ser metódico e independiente, como también, por mi capacidad para trabajar en equipo y por estar siempre orientado a resultados. Me motiva tomar nuevos desafíos y tratar de implementar soluciones que ayuden a los usuarios finales como a los objetivos de las empresas.',
        'en':'Graphic Designer specialized in Advertising from Universidad Santo Tomás, with over 14 years of experience in digital design, integration, and user interface design, mainly in fields such as e-commerce and IT. As a professional, I am known for being methodical and independent, as well as for my ability to work in a team and for always being results-oriented. I am motivated by taking on new challenges and striving to implement solutions that help both end users and company objectives.'
    },
    'abtst2':{
        'es':'Como persona',
        'en':'As a person'
    },
    'abtdes2':{
        'es':'Amigable y de buen trato, siempre abierto a escuchar y discutir, en buena, diferentes puntos de vista. Dentro de mis intereses y hobbies están la fotografía, la ilustración, el deporte y las motos. Me encanta salir a dar vueltas en moto, cambiar los paisajes por un rato y comer lo que toque en el viaje.',
        'en':'Friendly and approachable, always open to listening and discussing, in a good way, different points of view. Among my interests and hobbies are photography, illustration, sports, and motorcycles. I love going for rides on my motorcycle, changing the scenery for a while, and eating whatever comes my way during the trip.'
    },
    'abtstt1':{
        'es':'Educación, diplomado y cursos',
        'en':'Education, Diploma, and Courses',
    },
    'abtstdes1':{
        'es':'Algunos cursos y diplomados que he tomado duarante mi carrera para profundizar y ampliar conocimientos.',
        'en':'Some courses and diplomas I have taken during my career to deepen and broaden my knowledge.',
    },
    'abtstt2':{
        'es':'Experiencia profesional',
        'en':'Professional Experience',
    },
    'abtstdes2':{
        'es':'En mi carrera he estado muy ligado al diseño web y digital, con algunos extraños casos en que he podido ver temas de branding o ilustración.',
        'en':'Throughout my career, I have been closely linked to web and digital design, with a few unusual cases where I have dealt with branding or illustration topics.',
    }
}

export default aboutwords;