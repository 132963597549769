const formwords ={
    'lb1':{'es':'Tu nombre','en':'Name'},
    'lb2':{'es':'Ingresa tu correo','en':'Enter your email'},
    'lb3':{'es':'Ingresa el asunto','en':'Enter the subject of the message'},
    'lb4':{'es':'Escribe tu mensaje','en':'Enter you message'},
    'lb5':{'es':'Enviar mensaje','en':'Send message'},
    'plh1':{'es':'Ej: Fernando García','en':'Eg: Fernando García'},
    'plh2':{'es':'Ej:tucorreo@mail.com','en':'Eg: yourmail@mail.com'},
    'plh3':{'es':'Ej: cotización proyecto web','en':'Eg: web project quotation'},
    'msgOk':{'es':'Mail enviado con exito. Pronto me comunicaré contigo.','en':'Mail sent successfully. I will contact you soon.'},
    'msgErr':{'es':'Hubo un problema al enviar el mail. Por favor intenta nuevamente.','en':'There was a problem sending the mail. Please try again.'}
}

export default formwords;