import React from 'react';
import { useSelector } from 'react-redux';
import { languageSwitch } from '../../dictionary/translateFunctions.js';
import { conProyectos, conProyectosImg } from '../../conexiones/varConexiones.js';
import {fetchData} from '../../fetch/fetchData.js'
import './proyecto.css';

const apiData = fetchData(conProyectos);

const Proyecto = (props) => {
    const lang = useSelector((state) => state.lang.lang);
    const data = apiData.read();

    return(
        <>
            {data?.listaproyectos.map(
                (proyecto,index)=>{

                    if(proyecto.id === props.id){return<>
                    <div key={`render_${proyecto.id}`} id={`render_${proyecto.id}`} className='contenedor_text'>
                    <h2>{languageSwitch(proyecto.name,lang)}</h2>
                    <span className='contenedor_info'>
                        <h6>{proyecto.name !== '' ? proyecto.canales: ''}</h6>
                        <h6>{proyecto.cliente !== '' ? proyecto.cliente : ''}</h6>
                    </span>
                    <span className='contenedor_descripcion'>{
                        languageSwitch(proyecto.description,lang)
                        /* desc.split('<br />').map(
                            (parrafo,index) => {
                                return <p key={`p_${parrafo[index]}`}>{parrafo}</p>
                            }
                        ) */
                    }</span>
                    </div>
                    <div className='img'>
                        {   
                            proyecto.images !== null ? proyecto.images.split(',').map(
                                (imagen,index)=> {
                                    const img = conProyectosImg + props.cat+'/'+proyecto.name.es+'/'+imagen;
                                    return <img alt={`imagen_${proyecto.name.es}`} key={proyecto[index]} src={img.replace(/\s/g,"%20")} />
                                }):''
                        }
                    </div>
                    </>
                    }
                })
            }
        </>
    )
}

export default Proyecto;