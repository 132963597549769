const quehagowords ={
    "qt1":{"es":"¿A qué me dedico?","en":"What do I do?"},
    "qb1":{
            "qb1t1":{
                    "es":"Diseñador UX/UI",
                    "en":"UX/UI Designer"
            },
            "qb1d1":{
                    "es":"Tengo un enfoque en la solución de problemas, la entrega de valor y la provisión de soluciones eficientes y eficaces para los usuarios. Para esto implemento design thinkin, benchmarks (en todos sus tipos), entrevistas a usuarios, test con usuarios (tanto moderados como no moderados) y heurísticas.",
                    "en":"I focus on problem-solving, delivering value, and providing efficient and effective solutions for users. To achieve this, I implement design thinking, benchmarks (of all kinds), user interviews, user testing (both moderated and unmoderated), and heuristics."
            }

    },
    "qb2":{
            "qb2t2":{
                    "es":"Diseñador Gráfico",
                    "en":"Graphic Designer"
            },
            "qb2d2":{
                    "es":"He trabajado en diseño de marca, impresos, digital y gráfica para máqinas de vending. Como diseñador, me preocupo de involucrar a mis clientes, tratando de familiarizarme con sus necesidades y objetivos.",
                    "en":"I have worked in brand design, print, digital, and graphics for vending machines. As a designer, I make an effort to involve my clients, striving to familiarize myself with their needs and goals."
            }

    },
    "qb3":{
            "qb3t3":{
                    "es":"Front",
                    "en":"Front"
            },
            "qb3d3":{
                    "es":"Desde mis inicios he estado ligado a la web, y me desenvuelvo muy bien en HTML, CSS, Javascript y Jquery. Además, he hecho cursos para profundizar en desarrollo aprendiendo sobre React js, mysql y Java.",
                    "en":"Since the beginning of my career, I have been connected to the web, and I am proficient in HTML, CSS, JavaScript, and jQuery. Additionally, I have taken courses to deepen my development skills, learning about React.js, MySQL, and Java."
            }

    },
    "qbl":{
        "es":"Skills / Herramientas:",
        "en":"Skills & Tools:"
        },
    "qdwb":{
        "es":"Descargar Cv",
        "en":"Download sumary"
    }
}

export default quehagowords;