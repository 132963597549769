const slidewords ={
    'sldc':{
        'es':'Diseñador UX/UI - Diseñador Gráfico',
        'en':'UX/UI Designer - Graphic Designer'},
    'sldd':{
        'es':'Diseñador UX/UI con foco en el usuario, siempre buscando generar valor de cara a las personas y eficiencia para el negocio',
        'en':'UX/UI Designer with a user-focused approach, always seeking to generate value for people and efficiency for the business.'},
    'sllk':{
        'es':'Perfil en Linkedin',
        'en':'My profile on linkedin'
    },
}

export default slidewords;