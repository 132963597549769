import './contacto.css';
import React, { useEffect } from 'react';
import contactwords from './contactoDictionary';
import { useSelector } from 'react-redux';
import { languageSwitch } from '../../dictionary/translateFunctions';
import Formulario from '../../componentes/formulario/Formulario';

const Contacto = () => {
    const lang = useSelector((state) => state.lang.lang);
    const dictionary = contactwords;

    function scrollingWindows (){
        return window.scrollY > 0 ? window.scroll({top: 0,behavior: "smooth"}):null;
      }
      useEffect(()=>{
        scrollingWindows()
      })
    return(
        <div className='central'>
            <div className="contactoContenedor">
                <div className="contactoContext">
                    <h2>
                        {languageSwitch(dictionary.msg1,lang)}
                    </h2>
                    <p>
                        {languageSwitch(dictionary.msg2,lang)}
                    </p>
                </div>
                <div className="constactoForm">
                    <Formulario />
                </div>
            </div>
        </div>
    )
}

export default Contacto;