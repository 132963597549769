import './langswitch.css';
import { useSelector, useDispatch } from "react-redux";
import { setLang } from '../../store/langSlice';

const LangSwitch = () =>{
    const lang = useSelector((state) => state.lang.lang);
    const dispatch = useDispatch();

    const click = (e) => {
        dispatch(setLang(e));
    }

    return(
        <div className='langSwitcher'>
            {/* <div className='langSwitcher_label'>{lang === 'es'? 'idioma: ':'language: '}</div> */}
            <div className='langSwitcher_sp' onClick={()=>click('es')}><img alt="language spain flag" src={`./assets/icons/${lang==='es'?'flag=spn-on.svg':'flag=spn-off.svg'}`} /></div>
            <div className='langSwitcher_en' onClick={()=>click('en')}><img alt="language britain flag" src={`./assets/icons/${lang==='es'?'flag=brt-off.svg':'flag=brt-on.svg'}`} /></div>
        </div>
    )

}

export default LangSwitch;