import './bloque.css'
import quehagowords from './quehagoDictionary';
import { useSelector } from 'react-redux';
import { languageSwitch } from '../../dictionary/translateFunctions';
import { cvDownload } from '../../conexiones/varConexiones';

const Bloque = () => {
    const lang = useSelector((state) => state.lang.lang);
    const dictionary = quehagowords;
    return(
        <>
            <div className="section_quehago">
                <div className='titulo_queHago'>
                    <h2>{languageSwitch(dictionary.qt1,lang)}</h2>
                </div>
                <section>
                    <div className="section_quehago_column" style={{backgroundImage: `url('../assets/about/about_2.jpg')`}}>
                        <div>
                            <h2 className="section_quehago_head">{languageSwitch(dictionary.qb1.qb1t1,lang)}</h2>
                            <p className="section_quehago_text">
                                {languageSwitch(dictionary.qb1.qb1d1,lang)}
                            </p>
                            <span className="section_quehago_skills">
                                <h5>{languageSwitch(dictionary.qbl,lang)}</h5>
                                <img alt="classNamiconeicon" src="./assets/icons/figma.png" />
                                <img alt="icon_sketch" src="./assets/icons/sketch.png" />
                                <img alt="icon_maze" src="./assets/icons/maze.png" />
                                <img alt="icon_hotjar" src="./assets/icons/hotjar.png" />
                                <img alt="icon_miro" src="./assets/icons/miro.png" />
                                <img alt="icon_jira" src="./assets/icons/jira.png" />
                                <img alt="icon_scrum" src="./assets/icons/scrum.png" />
                            </span>
                        </div>
                    </div>
                    <div className="section_quehago_column"  style={{backgroundImage: `url('../assets/about/about_1.jpg')`}}>
                        <div>
                            <h2 className="section_quehago_head">{languageSwitch(dictionary.qb2.qb2t2,lang)}</h2>
                            <p className="section_quehago_text">
                            {languageSwitch(dictionary.qb2.qb2d2,lang)}
                            </p>
                            <span className="section_quehago_skills">
                                <h5>{languageSwitch(dictionary.qbl,lang)}</h5>
                                <img alt="icon_photosho" src="./assets/icons/phoshop.svg" />
                                <img alt="icon_illustrator" src="./assets/icons/illustrator.svg" />
                                <img alt="icon_indesign" src="./assets/icons/indesign.svg" />
                                <img alt="icon_after" src="./assets/icons/after.svg" />
                                <img alt="icon_premier" src="./assets/icons/premier.svg" />
                            </span>
                        </div>
                    </div>
                    <div className="section_quehago_column"  style={{backgroundImage: `url('../assets/about/about_3.jpg')`}}>
                        <div>
                            <h2 className="section_quehago_head">{languageSwitch(dictionary.qb3.qb3t3,lang)}</h2>
                            <p className="section_quehago_text">
                            {languageSwitch(dictionary.qb3.qb3d3,lang)}
                            </p>
                            <span className="section_quehago_skills">
                                <h5>{languageSwitch(dictionary.qbl,lang)}</h5>
                                <img alt="icon_css" src="./assets/icons/css.svg" />
                                <img alt="icon_html" src="./assets/icons/html.svg" />
                                <img alt="icon_javascript" src="./assets/icons/javascript.svg" />
                                <img alt="icon_jquery" src="./assets/icons/jquery.svg" />
                                <img alt="icon_react" src="./assets/icons/react.svg" />
                            </span>
                        </div>
                    </div>
                </section>
                <a className='boton_cv' href={cvDownload} target="_blank"> {languageSwitch(dictionary.qdwb,lang)}</a>{/* boton descarga */}
                

            </div>
        </>
    )
}


export default Bloque;