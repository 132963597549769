import React from 'react';
import { useSelector } from 'react-redux';
import { languageSwitch } from '../../dictionary/translateFunctions';
import { conMiniaturas } from '../../conexiones/varConexiones';
import './miniatura.css';
const Miniatura = (props) => {
    const lang = useSelector((state) => state.lang.lang);

    const img = conMiniaturas + props.categoria+'/'+props.titulo.es+'/thum_'+props.imagen;

    return(
        <div className='miniatura'
            onClick={()=>{props.action({type:'proy',on:true,id:props.proy,cat:props.categoria,scroll:false})}}>
            <div className='miniatura-main'>
            <div className='miniatura-img' 
                style={{backgroundImage : `url(${img.replace(/\s/g,"%20")})`}}>
            </div>
            <div className='miniatura-desc' data-elem="miniatura-wimg">
                <h6 className="">{props.año === null || props.año === '0' ? '':props.año}</h6>
                <h3>{languageSwitch(props.titulo,lang)}</h3>
                <h6 className="seccion_descripcion">{props.cliente}</h6>
            </div>
            </div>
        </div>
    )
}

export default Miniatura;