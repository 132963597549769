import './about.css';
import aboutwords from './aboutDictionary.js';
import { languageSwitch } from '../../dictionary/translateFunctions.js';
import { useSelector } from 'react-redux';
import { conCursos,conExperiencia } from '../../conexiones/varConexiones.js';
import React, { Suspense, useEffect } from 'react';
import {fetchData} from '../../fetch/fetchData.js';
import Pod from '../../componentes/pod/Pod';

const apiData = fetchData(conCursos);
const apiData2 = fetchData(conExperiencia);

const About = () => {
    const lang = useSelector((state) => state.lang.lang);
    const dictionary = aboutwords;

  const data = apiData.read();
  const data2 = apiData2.read();
  function scrollingWindows (){
    return window.scrollY > 0 ? window.scroll({top: 0,behavior: "smooth"}):null;
  }
  useEffect(()=>{
    scrollingWindows()
  })
  return (
    <Suspense fallback={<>Cargando...</>}>
    <div className="bloque_aboutme">
    <div className="bloque_aboutme_img" style={{backgroundImage: `url('./assets/about/moto_tratada.jpg')`}}></div>
</div>

<div className="body_aboutme">
    <div className="section_quienSoy">
        <div className='titulo_quienSoy'>
            <h2>{languageSwitch(dictionary.abtt1,lang)}</h2>
        </div>
        <section>
            <div className="section_quienSoy_column">
                <div style={{backgroundImage: `url('../assets/galeria/g_3.jpg')`}}>
                    <h2 className="section_quienSoy_head">{languageSwitch(dictionary.abtst1,lang)}</h2>
                    <p className="section_quienSoy_text">
                        {languageSwitch(dictionary.abtdes1,lang)}
                    </p>
                </div>
            </div>
            <div className="section_quienSoy_column" >
                <div>
                    <h2 className="section_quienSoy_head">{languageSwitch(dictionary.abtst2,lang)}</h2>
                    <p className="section_quienSoy_text">
                        {languageSwitch(dictionary.abtdes2,lang)}
                    </p>
                </div>
            </div>
        </section>

    </div>

    <div className="section_aboutme_experiencia">
        <div className="section_aboutme_block">
            <h2>{languageSwitch(dictionary.abtstt1,lang)}</h2>
            <p className="experiencia_bajada">{languageSwitch(dictionary.abtstdes1,lang)}</p>
            <div className="lineatiempo" id="educacion" data-targ="educacion">
              {data?.listacursos.map(//mapeando la lista de cursos
                            (ed)=>{
                                return <Pod key={ed.id_ed} objeto={ed} type={'ed'}></Pod>
                            }
                        )}
            </div>
        </div>
        <div className="section_aboutme_block">
            <h2>{languageSwitch(dictionary.abtstt2,lang)}</h2>
            <p className="experiencia_bajada">{languageSwitch(dictionary.abtstdes2,lang)}</p>
            <div className="lineatiempo" id="experiencia" data-targ="experiencia">
            {data2?.listaexperiencias.map(//mapeando la lista de cursos
                            (exp)=>{
                                return <Pod key={exp.id_exp} objeto={exp} type={'exp'} />
                            }
                        )}
            </div>
        </div>
    </div>
    </div>
    </Suspense>
  );
}

export default About;