import React, { useState } from 'react';
import menuwords from './menuDictionary';
import { languageSwitch } from '../../dictionary/translateFunctions';
import './menu.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cvDownload } from '../../conexiones/varConexiones';

const Menu = (props) => {
    const lang = useSelector((state) => state.lang.lang);
    const dictionary = menuwords;

    const [position,setPosition] = useState('home')
    const scrollToworks = () => {
        setTimeout(()=>{
            const el = document.querySelector(".main").getBoundingClientRect().top
            const scr = window.scrollY;
            const targ = scr + el - 90;
            setPosition('home')
            return window.scroll({top: targ ,behavior: "smooth"});
        },900)
      }
    return(
        <div id="menu_options" className="header_menu">
            <ul id="menu_options" className="header_menu_options">
                <Link className='Link' to="/Home"><li key="Home" data-bot="option" data-catid="0" id="_Home" onClick={()=>scrollToworks()}>{languageSwitch(dictionary.ht1,lang)}</li></Link>
                <Link className='Link' to="/About"><li key="about_me" data-bot="option" data-catid="1" id="_Sobre mi" onClick={()=>setPosition('about')}>{languageSwitch(dictionary.ht2,lang)}</li></Link>
                <Link className='Link' to="/Contacto"><li key="contacto" data-bot="option" data-catid="2" id="_Contacto" onClick={()=>setPosition('contact')}>{languageSwitch(dictionary.ht3,lang)}</li></Link>
                <a className='bot_dw_cv'  href={cvDownload} target="_blank">{languageSwitch(dictionary.ht4,lang)}</a>
            </ul>
        </div>
    )
}

export default (Menu);